import { graphql } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

interface Props {
    data: {
        markdownRemark: {
            frontmatter: {
                title: string;
                date: string;
            }
            html: string;
        }
    }
}

const PageTemplate = ({ data }: Props) => {
    const { frontmatter, html } = data.markdownRemark;
    return (
        <Layout>
            <SEO />
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '4rem 1rem',
                }}>
                <h1 style={{ textAlign: 'center' }}>{frontmatter.title}</h1>
                <p style={{ textAlign: 'center', maxWidth: 440 }}
                    dangerouslySetInnerHTML={{ __html: html }}
                />
            </div>
        </Layout>
    )
};

export default PageTemplate;

export const pageQuery = graphql`
query($path: String!) {
  markdownRemark(frontmatter: { path: { eq: $path }}) {
    html
    frontmatter {
      date(formatString: "MMM DD, YYYY")
      path
      title
    }
  }
}
`